import { Card, Icon } from 'oialbert-ui';
import { ReportType } from '../../../types/report';
import { getShortMonth } from '../../../utils/shortMonthName';

interface ReportListProps {
  report: ReportType;
}

export function ReportList({ report }: ReportListProps) {
  return (
    <Card className="mt-4 flex px-4 py-4 item justify-between">
      <p className="flex items-center gap-2">
        <div className="flex border border-gray-200 rounded-full w-10 h-10 items-center justify-center text-center">
          <Icon.MdCalendarMonth size={24} />
        </div>
        {getShortMonth(report?.month)} de {report?.year}
      </p>
      <a
        className="flex flex-row items-center gap-x-1 hover:text-neon-900 justify-center rounded-b"
        href={report.url}
        download
        target="_blank"
        rel="noreferrer"
      >
        <div className="mt-1">
          <Icon.MdFileDownload size={24} />
        </div>
      </a>
    </Card>
  );
}
