import { PaymentTypes, DeliveryTypes } from '../pages/Orders/types';

const payment_types = {
  credit_card: 'Cartão de crédito',
  debit_card: 'Cartão de débito',
  cash: 'Dinheiro',
  pix: 'Pix',
};

const delivery_types = {
  take_away: 'Retirada',
  delivery: 'Delivery',
};

export const profile_types: { [key: string]: string } = {
  products: 'produtos',
  services: 'serviços',
  delivery: 'delivery',
};

export const cashback_validity_types: { [key: string]: string } = {
  3: '3 dias',
  7: '7 dias',
  10: '10 dias',
  15: '15 dias',
  30: '30 dias',
};

export const getPaymentText = (type: PaymentTypes) => {
  return payment_types[type as PaymentTypes];
};

export const getDeliveryTypeText = (type: DeliveryTypes) => {
  return delivery_types[type as DeliveryTypes];
};

export const removeAllNumberAndSpecialCharacters = (value: string) => {
  return value.replace(/\d+/g, '');
};
