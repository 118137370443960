import classNames from 'classnames';

export const ButtonNext = ({
  title = 'próximo',
  nextStep,
  setIndex,
  index,
  nextIndex,
}: any) => {
  return (
    <button
      className={classNames(
        nextStep(index)
          ? 'bg-neon-900 text-white cursor-pointer'
          : 'bg-gray-100 cursor-block',
        'my-3 flex justify-center items-center py-2 px-4 rounded-lg '
      )}
      disabled={!nextStep(index)}
      onClick={(data) => {
        if (nextStep(index)) {
          setIndex(nextIndex);
        }
      }}
    >
      {title}
    </button>
  );
};
