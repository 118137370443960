// import NotAccessIllustration from '../../assets/illustrations/not-access.png';
import NotAccessIllustration from '../../assets/illustrations/not-access.svg';

interface AlertProps {
  children: React.ReactNode;
}

export function NotAccess({ children }: AlertProps) {
  return (
    <div className="w-full mt-8">
      <div className="flex items-center justify-center flex-col">
        <img
          width={300}
          src={NotAccessIllustration}
          alt="Ilustração de acesso negado"
        />
        {children}
      </div>
    </div>
  );
}
