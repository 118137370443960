import { useCallback, useState } from 'react';
import { CartData, CartItemData, getCartProps } from './types';
import * as Api from '../../services/extraServices';
import errorHandling from '../../utils/error_handling';
import { Product } from '../../types/extra-services/product';

const useLoaderCart = () => {
  const [isFetchingCart, setIsFetchingCart] = useState<boolean>(false);
  const [isFetchingCartItems, setIsFetchingCartItems] =
    useState<boolean>(false);
  const [isFetchingActiveProducts, setIsFetchingActiveProducts] =
    useState<boolean>(false);
  const [cart, setCart] = useState<CartData>({} as CartData);
  const [items, setItems] = useState<CartItemData[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const fetchCart = async ({
    dataCallback,
    isLoading,
    finishCallback,
    companyId,
  }: getCartProps) => {
    isLoading?.();
    const response = await Api.fetchCart(companyId);
    dataCallback?.(response);
    finishCallback?.();
  };

  const fetchActiveProducts = async ({
    dataCallback,
    isLoading,
    finishCallback,
    companyId,
  }: getCartProps) => {
    isLoading?.();
    const response = await Api.getActiveProducts(companyId);
    dataCallback?.(response);
    finishCallback?.();
  };

  const fetchCartItems = async ({
    dataCallback,
    isLoading,
    finishCallback,
    companyId,
  }: getCartProps) => {
    isLoading?.();
    const response = await Api.fetchCartItems(companyId);
    dataCallback?.(response);
    finishCallback?.();
  };

  const getActiveProducts = useCallback(
    (id: string) =>
      fetchActiveProducts({
        dataCallback: ({ data }) => {
          setProducts(data);
        },
        isLoading: () => setIsFetchingActiveProducts(true),
        finishCallback: () => setIsFetchingActiveProducts(false),
        companyId: id,
      }),
    []
  );

  const getCart = useCallback(
    (id: string) =>
      fetchCart({
        dataCallback: ({ data }) => {
          setCart(data);
        },
        isLoading: () => setIsFetchingCart(true),
        finishCallback: () => setIsFetchingCart(false),
        companyId: id,
      }),
    []
  );

  const getCartItems = useCallback(
    (id: string) =>
      fetchCartItems({
        dataCallback: ({ data }) => {
          setCart(data);
        },
        isLoading: () => setIsFetchingCartItems(true),
        finishCallback: () => setIsFetchingCartItems(false),
        companyId: id,
      }),
    []
  );

  return {
    getCart,
    isFetchingCart,
    cart,
    setIsFetchingCart,
    setCart,
    getCartItems,
    isFetchingCartItems,
    setIsFetchingCartItems,
    items,
    setItems,
    products,
    setProducts,
    isFetchingActiveProducts,
    setIsFetchingActiveProducts,
    getActiveProducts,
  };
};

export const useCart = () => {
  const {
    cart,
    isFetchingCart,
    setIsFetchingCart,
    setCart,
    isFetchingCartItems,
    setIsFetchingCartItems,
    items,
    setItems,
    products,
    setProducts,
    isFetchingActiveProducts,
    setIsFetchingActiveProducts,
  } = useLoaderCart();

  const getCurrentActiveProducts = useCallback(
    async (id: string) => {
      setIsFetchingActiveProducts(true);
      try {
        const response = await Api.getActiveProducts<Product[]>(id);

        if (response?.data) {
          setProducts(response.data);
        }
      } catch (err) {
        console.log('error getCurrentCart', err);
      } finally {
        setIsFetchingActiveProducts(false);
      }
    },
    [setIsFetchingActiveProducts, setProducts]
  );

  const getCurrentCart = useCallback(
    async (id: string) => {
      setIsFetchingCart(true);
      try {
        const response = await Api.fetchCart<CartData>(id);
        if (response) setCart(response);
      } catch (err) {
        console.log('error getCurrentCart', err);
      } finally {
        setIsFetchingCart(false);
      }
    },
    [setCart, setIsFetchingCart]
  );

  const getItems = useCallback(
    async (id: string) => {
      setIsFetchingCartItems(true);
      try {
        const response = await Api.fetchCartItems<CartItemData[]>(id);
        if (response) setItems(response);
      } catch (err) {
        console.log('error getItems', err);
      } finally {
        setIsFetchingCartItems(false);
      }
    },
    [setIsFetchingCartItems, setItems]
  );

  const handleRemoveItem = useCallback(
    async (packageId: string, token: string, companyId: string) => {
      setIsFetchingCartItems(true);
      try {
        return await Api.deleteCartItem({ package_id: packageId, token });
      } catch (err) {
        errorHandling(err, 'erro ao deletar uma promoção', 'crema');
        setIsFetchingCartItems(false);
      } finally {
        await Promise.all([
          await getItems(companyId),
          await getCurrentActiveProducts(companyId),
          await getCurrentCart(companyId),
        ]);
        setIsFetchingCartItems(false);
      }
    },
    [setIsFetchingCartItems, getCurrentActiveProducts, getCurrentCart, getItems]
  );

  return {
    getCurrentCart,
    cart,
    isFetchingCart,
    items,
    getItems,
    isFetchingCartItems,
    handleRemoveItem,
    getCurrentActiveProducts,
    products,
    isFetchingActiveProducts,
  };
};
