import { IonPage, IonHeader, IonContent, useIonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import { Header } from 'oialbert-ui';
import { Navbar } from '../../components/Navbar';
import Form from './components/Form';
import { createRef, useCallback, useEffect } from 'react';
import { OutputImg } from './components/OutputImg';
import PromotionContextProvider, {
  usePromotionContext,
} from '../../contexts/PromotionContext';
import { TYPE } from './types';
import { format } from 'date-fns';
import errorHandling from '../../utils/error_handling';
import { checkLimit } from '../../services/contracts';
import { LIMIT_TYPE } from '../Promotions/types';

const CreatePromotions = () => {
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const formRef: any = createRef();
  const { preview, handleCreatePromotion } = usePromotionContext();

  const handleCheckLimit = useCallback(async () => {
    try {
      const response = await checkLimit(LIMIT_TYPE.limit_promotions);

      if (response?.available <= 0) {
        const message = `Parece que você atingiu o limite de promoções ativas simultâneas. Verifique as suas promoções ativas para liberar espaço para um novo cadastro.

          Se precisar de ajuda, entre em contato, ok?! Estamos prontos para te ajudar.`;

        const buttons = [
          {
            text: 'ok',
            role: 'confirm',
            handler: () => {
              history.push('/promotions');
            },
          },
        ];

        presentAlert({
          header: 'Hey! Limite de promoções ativas excedido.',
          message,
          buttons,
        });
      }
    } catch (error) {
      errorHandling(error, 'ocorreu um erro ao checar o limite!', 'coral');
    }
  }, [history, presentAlert]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        await handleCreatePromotion(e);
        errorHandling(null, 'promoção criada com sucesso!', 'success');
      } catch (error) {
        errorHandling(error, 'ocorreu um erro ao salvar a promoção!', 'coral');
      } finally {
        history.goBack();
      }
    },
    [handleCreatePromotion, history]
  );

  useEffect(() => {
    handleCheckLimit();
  }, [handleCheckLimit]);

  return (
    <IonPage>
      <IonHeader className="sm:hidden ion-no-border shadow-sm bg-white">
        <section className="mt-10 px-5 pb-5 ">
          <Header onClick={() => history.goBack()} title="Promoções" />
        </section>
      </IonHeader>
      <IonContent>
        <section className="sm:flex p-3 gap-3 min-h-screen bg-gray-50">
          <Navbar />

          <section className="lg:p-6 rounded-xl w-full lg:shadow-lg shadow-gray-100 bg-white ">
            <div className="hidden sm:flex flex-col p-3">
              <h1 className="text-xl font-semibold">Promoções</h1>
              <h2 className="text-gray-500">
                ofereça promoções aos associados albert
              </h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 p-3">
              <Form formRef={formRef} onSubmit={handleSubmit} />
              <div className="hidden sm:flex flex-col w-96 gap-x-6 gap-3 ">
                <div>
                  <h1 className="font-semibold text-sm">
                    visualização prévia do cartão de promoção
                  </h1>
                  <h2 className="text-gray-500 font-light text-sm">
                    veja como será apresentado o card de promoções no app de
                    associados.
                  </h2>
                </div>
                <div className="flex flex-col shadow-lg rounded-2xl border border-gray-100 p-6 gap-3">
                  {(preview?.type === TYPE.discount ||
                    preview?.type === TYPE.combo ||
                    preview?.type === TYPE.offer) &&
                  preview.discount_amount > 0 &&
                  preview.discount_amount < 99 ? (
                    <span className="text-2xl font-bold text-neon-900">{`${preview.discount_amount}% de desconto`}</span>
                  ) : (
                    preview?.discount_amount === 100 && (
                      <span className="text-2xl font-bold text-green-500">{`grátis`}</span>
                    )
                  )}
                  <section>
                    <h1 className="text-gray-700 font-semibold">
                      {preview?.name ?? 'she drinks'}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          preview?.description ??
                          `<p>promocao no cardápio completo de drinks,</br> só para mulheres</p>`,
                      }}
                      className="text-sm text-gray-500"
                    />
                  </section>
                  <section className="flex gap-3">
                    <span className="text-xs bg-gradient-to-r from-neon-900 to-coral-900 text-white font-semibold py-2 px-4 rounded-lg">
                      {preview?.start_date &&
                        preview?.end_date &&
                        `${format(
                          new Date(preview.start_date),
                          'HH:mm'
                        )} ás ${format(new Date(preview.end_date), 'HH:mm')}`}
                    </span>
                  </section>
                  <span className="text-gray-600 text-sm">
                    {preview?.start_date &&
                      preview?.end_date &&
                      `válido de ${format(
                        new Date(preview.start_date),
                        'dd/MM/yyyy'
                      )} a ${format(new Date(preview.end_date), 'dd/MM/yyyy')}`}
                  </span>
                  <span className="font-semibold text-gray-700 text-sm hover:underline cursor-pointer">
                    confira as condições aqui
                  </span>
                  <div className="flex">{preview && <OutputImg />}</div>
                  <section>
                    <h6 className="font-semibold text-gray-700 text-sm">
                      aproveite este benefício!
                    </h6>
                    <p className="text-sm text-gray-700">
                      apresente esse card no estabelecimento presencialmente.
                    </p>
                  </section>
                  <div className="flex justify-center">
                    <button className="my-3 flex justify-center items-center bg-gradient-to-r from-neon-900 to-coral-900 text-white text-sm font-semibold py-2 px-4 rounded-lg cursor-pointer">
                      ativar promoção
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </IonContent>
    </IonPage>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <PromotionContextProvider>
    <CreatePromotions />
  </PromotionContextProvider>
);
