import { api } from './config';

type ListReportsParams = {
  page?: number;
  limit?: number;
  query?: string;
  companyId?: string;
};

export const listReports = async ({
  page,
  limit,
  query,
  companyId,
}: ListReportsParams) => {
  const searchFilter = query ? `?year=${query}` : '';

  const config: any = {
    params: {
      page,
      limit,
    },
  };

  if (companyId) {
    config.headers = {
      company_id: companyId,
    };
  }

  const { data } = await api.get(
    `/v2/partners/companies/reports${searchFilter}`,
    config
  );

  return data;
};
