import { api } from '../config';

export const bankAccounts = async () => {
  const { status, data } = await api.get('/v1/partners/banks');

  if (status === 200 && data) {
    return data;
  }

  return false;
};
