import { LIMIT_TYPE } from '../pages/Promotions/types';
import { CompanyData } from '../types/company';
import { PartnersContractsItemsPermission } from '../types/partner-contracts-items-permission';
import { getData } from '../utils/storage';
import { api } from './config';

export type types = LIMIT_TYPE;
const TYPE_PERMISSION_REPORT = 'report_access';

const key = '@AlbertPartners::company';

export const checkLimit = async (type: types) => {
  const company: CompanyData = JSON.parse(
    (await getData(key)).value ?? ''
  ) as CompanyData;
  const { data } = await api.get(`/v2/partners/contracts/items?type=${type}`, {
    headers: {
      company_id: company.id,
    },
  });
  return data;
};

export const checkReportAccessPermission =
  async (): Promise<PartnersContractsItemsPermission> => {
    const { data } = await api.get(
      `/v2/partners/contracts/items?type=${TYPE_PERMISSION_REPORT}`,
      {
        headers: {},
      }
    );
    return data;
  };

export const checkPartnersContractsItemsReportAccessPermission = async (
  type: 'recovery_cashback' | 'report_access' | 'limit_promotions'
): Promise<PartnersContractsItemsPermission> => {
  const company: CompanyData = JSON.parse(
    (await getData(key)).value ?? ''
  ) as CompanyData;
  const { data }: { data: PartnersContractsItemsPermission } = await api.get(
    `/v2/partners/contracts/items?type=${type}`,
    {
      headers: {
        company_id: company.head_office_uuid ?? company.id,
      },
    }
  );
  return data;
};

export const checkPartnersContractsItemsPermission = async (
  type:
    | 'ai_free'
    | 'ai_premium'
    | 'limit_promotions'
    | 'login'
    | 'report_access'
    | 'bi_access'
    | 'recovery_cashback'
): Promise<PartnersContractsItemsPermission> => {
  const { data }: { data: PartnersContractsItemsPermission } = await api.get(
    `/v2/partners/contracts/items?type=${type}`
  );
  return data;
};
