export const Panel = ({ title, description, children }: any) => {
  return (
    <div className="flex flex-col gap-y-2 mt-6">
      <h3 className="text-gray-700 font-semibold text-sm">{title}</h3>
      <p
        dangerouslySetInnerHTML={{ __html: description }}
        className="text-gray-500 font-light text-xs "
      />
      <div>{children}</div>
    </div>
  );
};
