import { useCallback, useState } from 'react';
import SwitchInput from './SwitchInput';

export const AvailabilityDays = ({ onChange, value, error }: any) => {
  const [availabilityDays, setAvailabilityDays] = useState<string[]>(
    value || []
  );

  const handleAvailabilityDays = useCallback(
    (bol: boolean, day: string) => {
      setAvailabilityDays((prevAvailabilityDays) => {
        let newAvailabilityDays;
        if (bol && !prevAvailabilityDays.includes(day)) {
          newAvailabilityDays = [...prevAvailabilityDays, day];
        } else if (!bol && prevAvailabilityDays.includes(day)) {
          newAvailabilityDays = prevAvailabilityDays.filter(
            (item) => item !== day
          );
        } else {
          newAvailabilityDays = prevAvailabilityDays;
        }
        onChange(newAvailabilityDays);
        return newAvailabilityDays;
      });
    },
    [onChange]
  );

  return (
    <>
      {days.map((day, index) => (
        <SwitchInput
          key={`key-switch-${day.value}-${index}`}
          value={day.value}
          label={day.label}
          onChange={handleAvailabilityDays}
          checked={availabilityDays.includes(day.value)}
        />
      ))}

      {error && (
        <p className="mt-3 text-sm text-neon-900 w-full lowercase">* {error}</p>
      )}
    </>
  );
};

const days: { value: string; label: string }[] = [
  { value: '0', label: 'domingo' },
  { value: '1', label: 'segunda-feira' },
  { value: '2', label: 'terça-feira' },
  { value: '3', label: 'quarta-feira' },
  { value: '4', label: 'quinta-feira' },
  { value: '5', label: 'sexta-feira' },
  { value: '6', label: 'sábado' },
];
