import { useState, useCallback } from 'react';

import * as Api from '../../services/orders';
import errorHandling from '../../utils/error_handling';
import { getOrdersProps, Order, OrdersFilters, STATUS } from './types';

const DEFAULT_VALUES: getOrdersProps = {
  dataCallback: () => {},
  isLoading: () => {},
  finishCallback: () => {},
  limit: 5,
  page: 1,
};

const DEFAULT_ORDERS_FILTERS: OrdersFilters = {
  limit: 5,
};

const useLoaderOrders = () => {
  const [fetchingOrders, isFetchingOrders] = useState<boolean>(false);
  const [fetchingInProgressOrders, isFetchingInProgressOrders] =
    useState<boolean>(false);
  const [fetchingForDeveliveryOrders, isFetchingForDeveliveryOrders] =
    useState<boolean>(false);
  const [fetchingDeliveredOrders, isFetchingDeliveredOrders] =
    useState<boolean>(false);
  const [openedOrders, setOpenedOrders] = useState<Order[]>([]);
  const [inProgressOrders, setInProgressOrders] = useState<Order[]>([]);
  const [ordersForDelivery, setOrdersForDelivery] = useState<Order[]>([]);
  const [ordersDelivered, setOrdersDelivered] = useState<Order[]>([]);

  const [pageOpenedOrders, setPageOpenedOrders] = useState<number>(1);
  const [pageInProgressOrders, setPageInProgressOrders] = useState<number>(1);
  const [pageDelivered, setPageDelivered] = useState<number>(1);
  const [pageForDelivered, setPageForDelivered] = useState<number>(1);
  const [totalOpenedOrders, setTotalOpenedOrders] = useState<number>(1);
  const [totalInProgressOrders, setTotalInProgressOrders] = useState<number>(1);
  const [totalDelivered, setTotalDelivered] = useState<number>(0);
  const [totalForDelivered, setTotalForDelivered] = useState<number>(0);

  const fetchOrders = async ({
    page,
    limit,
    status,
    dataCallback,
    isLoading,
    finishCallback,
  }: getOrdersProps = DEFAULT_VALUES) => {
    isLoading?.();
    const response = await Api.fetchOrders({ limit, status, page });
    dataCallback?.(response.orders);
    finishCallback?.();
  };

  const getOpenedOrders = useCallback(
    ({ limit }: OrdersFilters = DEFAULT_ORDERS_FILTERS) =>
      fetchOrders({
        page: 1,
        status: [STATUS.opened],
        dataCallback: ({ data, meta }) => {
          setOpenedOrders(data);
          setTotalOpenedOrders(meta.total);
        },
        isLoading: () => isFetchingOrders(true),
        finishCallback: () => isFetchingOrders(false),
        limit,
      }),
    []
  );

  const getInProgressOrders = useCallback(
    ({ limit }: OrdersFilters = DEFAULT_ORDERS_FILTERS) =>
      fetchOrders({
        page: 1,
        status: [STATUS.in_progress],
        dataCallback: ({ data, meta }) => {
          setInProgressOrders(data);
          setTotalInProgressOrders(meta.total);
        },
        isLoading: () => isFetchingInProgressOrders(true),
        finishCallback: () => isFetchingInProgressOrders(false),
        limit,
      }),
    []
  );

  const getOrdersForDelivery = useCallback(
    ({ limit }: OrdersFilters = DEFAULT_ORDERS_FILTERS) =>
      fetchOrders({
        page: pageForDelivered,
        status: [STATUS.in_route, STATUS.waiting],
        dataCallback: ({ data, meta }) => {
          setOrdersForDelivery(data);
          setTotalForDelivered(meta.total);
        },
        isLoading: () => isFetchingForDeveliveryOrders(true),
        finishCallback: () => isFetchingForDeveliveryOrders(false),
        limit,
      }),
    [pageForDelivered]
  );

  const getOrdersDelivered = useCallback(
    ({ limit }: OrdersFilters = DEFAULT_ORDERS_FILTERS) =>
      fetchOrders({
        page: pageDelivered,
        status: [STATUS.delivered],
        dataCallback: ({ data, meta }) => {
          setOrdersDelivered(data);
          setTotalDelivered(meta.total);
        },
        isLoading: () => isFetchingDeliveredOrders(true),
        finishCallback: () => isFetchingDeliveredOrders(false),
        limit,
      }),
    [pageDelivered]
  );

  const loadOrders = useCallback(
    async ({ limit }: OrdersFilters = DEFAULT_ORDERS_FILTERS) => {
      try {
        await getOpenedOrders({ limit });
        await getInProgressOrders({ limit });
        await getOrdersForDelivery({ limit });
        await getOrdersDelivered({ limit });
      } catch (err) {
        console.log('error getOrders', err);
      }
    },
    [
      getInProgressOrders,
      getOpenedOrders,
      getOrdersDelivered,
      getOrdersForDelivery,
    ]
  );

  const changeStatus = useCallback(
    async (id: string, status: STATUS) => {
      try {
        await Api.updateStatus(id, status);

        if (status === STATUS.canceled) {
          errorHandling(null, 'pedido cancelado', 'success');
          return;
        } else {
          errorHandling(null, 'pedido atualizado', 'success');
        }

        await loadOrders({ limit: 5 });
      } catch (err) {
        errorHandling(err, 'erro ao alterar status', 'crema');
      }
    },
    [loadOrders]
  );

  const confirmCode = useCallback(
    async (id: string, code: string) => {
      await Api.deliverOrder(id, code);
      await loadOrders({ limit: 5 });
    },
    [loadOrders]
  );

  return {
    getOpenedOrders,
    getInProgressOrders,
    getOrdersForDelivery,
    loadOrders,
    changeStatus,
    confirmCode,
    openedOrders,
    inProgressOrders,
    ordersForDelivery,
    fetchingOrders,
    fetchingInProgressOrders,
    fetchingForDeveliveryOrders,
    fetchingDeliveredOrders,
    ordersDelivered,
    pageDelivered,
    totalDelivered,
    setPageDelivered,
    pageForDelivered,
    totalForDelivered,
    setPageForDelivered,
    pageOpenedOrders,
    totalOpenedOrders,
    setPageOpenedOrders,
    pageInProgressOrders,
    totalInProgressOrders,
    setPageInProgressOrders,
  };
};

export const useOrders = () => {
  const [order, setOrder] = useState<Order>({} as Order);
  const [fetchingOrder, isFetchingOrder] = useState<boolean>(false);

  const {
    getOpenedOrders,
    getInProgressOrders,
    getOrdersForDelivery,
    loadOrders,
    changeStatus,
    confirmCode,
    openedOrders,
    inProgressOrders,
    ordersForDelivery,
    fetchingOrders,
    fetchingInProgressOrders,
    fetchingForDeveliveryOrders,
    fetchingDeliveredOrders,
    ordersDelivered,
    pageDelivered,
    totalDelivered,
    setPageDelivered,
    pageForDelivered,
    totalForDelivered,
    setPageForDelivered,
    pageOpenedOrders,
    totalOpenedOrders,
    setPageOpenedOrders,
    pageInProgressOrders,
    totalInProgressOrders,
    setPageInProgressOrders,
  } = useLoaderOrders();

  const getOrder = useCallback(async (id: string) => {
    isFetchingOrder(true);
    try {
      const response = await Api.fetchOrder(id);
      setOrder(response.order);
    } catch (err) {
      console.log('error getOrder', err);
    } finally {
      isFetchingOrder(false);
    }
  }, []);

  return {
    getOpenedOrders,
    getInProgressOrders,
    getOrdersForDelivery,
    getOrder,
    loadOrders,
    changeStatus,
    confirmCode,
    openedOrders,
    order,
    inProgressOrders,
    ordersForDelivery,
    fetchingOrder,
    fetchingOrders,
    fetchingInProgressOrders,
    fetchingForDeveliveryOrders,
    fetchingDeliveredOrders,
    ordersDelivered,
    pageDelivered,
    totalDelivered,
    setPageDelivered,
    pageForDelivered,
    totalForDelivered,
    setPageForDelivered,
    pageOpenedOrders,
    totalOpenedOrders,
    setPageOpenedOrders,
    pageInProgressOrders,
    totalInProgressOrders,
    setPageInProgressOrders,
  };
};
