import { IonSpinner } from '@ionic/react';

export const Loading = ({
  transparent,
  text,
}: {
  transparent?: boolean;
  text?: string;
}) => (
  <section
    className={`flex flex-col ${
      transparent ? '' : 'bg-white'
    } w-full h-full items-center justify-center`}
  >
    <IonSpinner className="text-neon-900" />
    {text && <p className="text-sm mt-4">{text}</p>}
  </section>
);
