import { api } from '../config';

export const balanceCharges = async (params = {}) => {
  const { status, data } = await api.get('/v1/partners/balance/charges', {
    params,
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};
