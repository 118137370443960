import React, { useState } from 'react';
import { Switch } from '@headlessui/react';

interface SwitchInputProps {
  label: string;
  value: string;
  onChange: any;
  checked: boolean;
}

export default function SwitchInput({
  label,
  value,
  onChange,
  checked,
}: SwitchInputProps) {
  const [enable, setEnable] = useState<boolean>(checked ? true : false);

  return (
    <div className="flex justify-between items-center border-b p-3">
      <span className="text-gray-700 text-sm">{label}</span>
      <Switch
        checked={enable}
        onChange={() => {
          setEnable(!enable);
          onChange(!enable, value);
        }}
        className={`${
          enable ? 'bg-neon-900' : 'bg-gray-200'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            enable ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
}
