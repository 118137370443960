import { CompanyData } from '../types/company';
import { getData } from '../utils/storage';
import { api } from './config';
import {
  CustomersCashbackParams,
  CustomersCashbackResponse,
} from './types/cashbacksExpired';

const key = '@AlbertPartners::company';

export const reactivateCashback = async <T>(
  ids: string[],
  new_days: string
) => {
  const company: CompanyData = JSON.parse(
    (await getData(key)).value ?? ''
  ) as CompanyData;

  const { status, data } = await api.post(
    `/v2/partners/cashback/recovery`,
    {
      idx_transactions: ids,
      new_days: new_days,
    },
    {
      headers: {
        company_id: company.head_office_uuid ?? company.id,
      },
    }
  );

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const customersCashbackRequest = async (
  params?: CustomersCashbackParams
): Promise<CustomersCashbackResponse> => {
  try {
    const company: CompanyData = JSON.parse(
      (await getData(key)).value ?? ''
    ) as CompanyData;

    const { data } = await api.get<CustomersCashbackResponse>(
      '/v2/partners/cashback/recovery/customers',
      {
        params: {
          ...params,
        },
        headers: {
          company_id: company.head_office_uuid ?? company.id,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error('Erro ao filtrar associados');
  }
};
