import { InputHTMLAttributes, forwardRef } from 'react';

import styles from './styles.module.css';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Component: React.ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  { label, ...rest },
  ref
) => {
  return (
    <label className="flex-1">
      <input ref={ref} {...rest} type="radio" className={styles.radio} />
      <span className={styles.label + ' whitespace-nowrap'}>{label}</span>
    </label>
  );
};

export const LabeledCheckbox = forwardRef(Component);
