import classNames from 'classnames';
import { STATUS } from '../types';

const Status = ({ status }: { status: STATUS }) => {
  return (
    <div className="flex justify-start items-center gap-3 relative">
      <span
        className={classNames(
          status === 'actived'
            ? 'bg-teal-300'
            : status === 'paused'
            ? 'bg-yellow-300'
            : status === 'pending'
            ? 'bg-blue-400'
            : status === 'refused'
            ? 'bg-red-500'
            : status === 'expired'
            ? 'bg-orange-500'
            : 'bg-gray-500',
          'h-3 w-3 absolute rounded-full'
        )}
      ></span>
      <span className="pl-5 text-gray-500 text-xs">
        {status === 'actived'
          ? 'ativado'
          : status === 'paused'
          ? 'pausado'
          : status === 'pending'
          ? 'em análise'
          : status === 'deleted'
          ? 'deletado'
          : status === 'refused'
          ? 'recusado'
          : status === 'expired'
          ? 'expirado'
          : status}
      </span>
    </div>
  );
};

export default Status;
