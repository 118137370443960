import { api } from '../config';
import type { BankAccount } from '../../types/bankAccounts';

export const banks = async () => {
  const { status, data } = await api.get('v1/banks');

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createBank = async (payload: BankAccount['bank_account']) => {
  const { status, data } = await api.post('/v1/partners/banks', payload);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
