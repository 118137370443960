import { Toast } from 'oialbert-ui';

type ErrorHandlingVariantProp = 'success' | 'crema' | 'warning' | 'coral';

const mappingErrorsFields: { [key: string]: string } = {
  document_number: 'CPF',
  email: 'e-mail',
  payment_types: 'formas de pagamento',
};

const mappingErrors: { [key: string]: string } = {
  unique: 'já existe',
  documentNumber: 'é inválido',
  minLength: 'deve ter pelo menos 1',
};

const buildMessageGenericError = (field: string, error: string): string => {
  return `${mappingErrorsFields[field]} ${mappingErrors[error]}`;
};

export default function errorHandling(
  error: any,
  message: string,
  variant: ErrorHandlingVariantProp = 'crema'
) {
  const isError = error?.response;

  if (isError) {
    if (error?.response?.data?.errors?.length) {
      error?.response?.data?.errors.map((e: any) => {
        if (!e.field && !e.rule) {
          return Toast[variant](e.message);
        }

        if (e.message) {
          return Toast[variant](e.message);
        }

        return Toast[variant](buildMessageGenericError(e.field, e.rule));
      });
    } else {
      return Toast[variant](
        error?.response?.data?.message ??
          error?.response?.data?.error ??
          message
      );
    }
    return;
  }

  return Toast[variant](message);
}
