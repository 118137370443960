import { IonPage, IonContent } from '@ionic/react';
import { useRef } from 'react';
import { useHistory } from 'react-router';
import { useMessages } from './MessagesContext';
import LogoWhiteSimple from '../../assets/logo-white-simple.svg';
import LogoPergunteAlbert from '../../assets/chat.png';
import ArrowGoIcon from '../../assets/arrow-go.svg';
import ChatIcon from '../../assets/message-circle.svg';
import './scrollbar.css';

const PergunteAlbert: React.FC = () => {
  const { addMessage } = useMessages();
  const history = useHistory();
  const messages = [
    {
      content: 'Monte uma tabela com promoções e cashback para o dia das mães.',
    },
    {
      content:
        'Como faço para atrair e fidelizar clientes para meu negócio? Me dê 5 dicas arrasadoras.',
    },
    {
      content:
        'Monte uma vitrine deslumbrante para atrair mais consumidores para minha loja?',
    },
    { content: 'Quais ações posso tomar para motivar meus colaboradores?' },
    {
      content:
        'Crie um plano de remuneração e comissionamento para minha equipe de vendas.',
    },
    {
      content:
        'Forneça um roteiro de marketing e vendas para ajudar minha equipe a aumentar a atração e fidelização dos clientes.',
    },
  ];
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const sendMessage = async (input?: string) => {
    if (input) {
      const message = { content: input };
      addMessage(message);
    }
    history.push('/pergunte-ao-albert/chat');
  };

  return (
    <IonPage className="overflow-hidden">
      <IonContent>
        <div className="flex flex-col items-center min-h-screen bg-white normal-case overflow-hidden">
          <div className="w-full bg-white fixed">
            <div
              className="flex items-center justify-center bg-neon-900 text-white p-4"
              style={{ height: '62px' }}
            >
              <img src={LogoWhiteSimple} alt="Logo" />
            </div>
          </div>

          <div
            className="chat w-full bg-white flex flex-col overflow-y-auto"
            style={{ marginTop: '62px', marginBottom: '90px' }}
          >
            <div className="flex items-center justify-center text-center mt-8">
              <img
                src={LogoPergunteAlbert}
                width={130}
                height={137}
                alt="Chat Logo"
              />
            </div>

            <div className="text-center mb-8">
              <span className="bg-white text-md normal-case text-gray-800 font-bold block w-60 mx-auto">
                Escolha uma pergunta ou digite algo para começar
              </span>
            </div>

            <div className="bg-white w-full max-w-xl px-4 mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-0.5">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    onClick={() => sendMessage(message.content)}
                    className="shadow-lg cursor-pointer m-2 bg-neon-900 rounded-lg relative"
                    style={{
                      borderRadius: '15px',
                      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 6px 20px',
                    }}
                  >
                    <div className="font-inter text-white text-sm font-semibold text-center p-2 m-2 mb-3 max-w-lg">
                      {message.content}
                    </div>
                    <div className="text-right mb-1 text-xs absolute bottom-3 right-2">
                      <img
                        src={ArrowGoIcon}
                        className="mr-2"
                        width="15px"
                        alt="Arrow Icon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div ref={messagesEndRef} />
          </div>

          <div
            className="flex flex-col px-4 items-center w-full fixed bottom-0 bg-white"
            style={{ height: '90px' }}
          >
            <button
              onClick={() => sendMessage()}
              className="normal-case absolute top-4 p-4 px-6 right-10 p-2 text-sm font-bold flex bg-neon-900 text-white rounded-full hover:bg-neon-900 disabled:opacity-50"
            >
              <img src={ChatIcon} className="mr-2" alt="Chat Icon" />{' '}
              <span className="normal-case mt-0.5">Novo chat</span>
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PergunteAlbert;
