import React from 'react';
import { Icon } from 'oialbert-ui';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { usePromotionContext } from '../../../../contexts/PromotionContext';
import getCroppedImg from '../../../../utils/cropImages';
import { IonSpinner } from '@ionic/react';
import BannerPromotion from '../../../../assets/illustrations/banner-promotion.png';
export const Banner = ({
  onChange,
  previewBanner,
}: {
  onChange: any;
  previewBanner: string | undefined;
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { applyPreview, cropAreaAspect } = usePromotionContext();
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCrop = useCallback(() => {
    setLoading(true);
    getCroppedImg(previewBanner, croppedAreaPixels)
      .then((croppedImageUrl) => {
        applyPreview(croppedImageUrl);
        onChange(croppedImageUrl);
      })
      .finally(() => setLoading(false));
  }, [applyPreview, croppedAreaPixels, onChange, previewBanner]);

  const handleCropChange = useCallback(
    (croppedArea) => {
      applyPreview({
        croppedArea,
      });
    },
    [applyPreview]
  );

  return (
    <div className="flex flex-col items-center justify-center">
      <Cropper
        image={previewBanner || BannerPromotion}
        crop={crop}
        zoom={zoom}
        aspect={cropAreaAspect}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropAreaChange={handleCropChange}
        onCropComplete={onCropComplete}
      />
      <div className="mt-12 absolute bottom-2 ">
        <button
          type="button"
          onClick={onCrop}
          className="bg-white shadow-lg hover:text-gray-700 text-black font-bold py-2 px-4 rounded"
        >
          {loading ? (
            <div className="flex items-center gap-3">
              <IonSpinner className="h-4 w-4" />
              aplicando
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <Icon.MdAspectRatio name="crop" />
              aplicar
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
