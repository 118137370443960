export function isNumber(n: string | any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function discountAmount(value: string): number {
  const sanitizedValue = value.replace(/[^\d.]/g, '');
  const truncatedValue = sanitizedValue.slice(0, 5);
  const numericValue = parseFloat(truncatedValue);
  const newValue = numericValue > 70 ? 70 : numericValue;
  return newValue;
}
