import {
  useForm as useHookForm,
  FieldValues,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export default function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(
  props: UseFormProps<TFieldValues, TContext> & { schema?: any }
): UseFormReturn<TFieldValues, TContext> {
  return useHookForm({
    ...props,
    ...(props.schema && { resolver: zodResolver(props.schema) }),
  });
}
