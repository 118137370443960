import React, { createContext, ReactNode, useContext } from 'react';
import { CartData, CartItemData } from './types';
import { useCart as useCartHook } from './hooks';
import { Product } from '../../types/extra-services/product';

type ContextProps = {
  items: CartItemData[];
  isFetchingCart: boolean;
  getItems: (id: string) => Promise<void>;
  getCurrentCart: (id: string) => Promise<void>;
  cart: CartData;
  getCurrentActiveProducts: (id: string) => Promise<void>;
  removeItem: (packageId: string, token: string, companyId: string) => void;
  products: Product[];
  isFetchingActiveProducts: boolean;
};

const Context = createContext<ContextProps>({} as ContextProps);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const {
    items,
    getItems,
    isFetchingCartItems,
    getCurrentCart,
    cart,
    getCurrentActiveProducts,
    handleRemoveItem,
    products,
    isFetchingActiveProducts,
  } = useCartHook();

  return (
    <Context.Provider
      value={{
        items,
        isFetchingCart: isFetchingCartItems,
        getItems,
        getCurrentCart,
        cart,
        getCurrentActiveProducts,
        removeItem: handleRemoveItem,
        products,
        isFetchingActiveProducts,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCart = () => useContext<ContextProps>(Context);
