import { IonContent, IonPage } from '@ionic/react';

import Illustration from '../../assets/illustrations/confirmed_payment.svg';
import { Button } from 'oialbert-ui';
import { Redirect, useHistory, useLocation } from 'react-router';

function ruleOfThree(value: number, base = 812) {
  return (value * window.innerHeight) / base;
}

const ExtraServicesCheckoutSuccess: React.FC = () => {
  const { state } = useLocation<{ origin: string }>();
  const history = useHistory();

  if (!state?.origin || state.origin !== 'extra-services') {
    return <Redirect to="/extra-services" />;
  }

  return (
    <IonPage>
      <IonContent>
        <div className="flex flex-col py-3 justify-center h-full px-4 gap-20">
          <div className="flex justify-center">
            <img
              src={Illustration}
              height={ruleOfThree(300)}
              width={ruleOfThree(300)}
              alt="uma mulher olhando para uma tela de celular confirmando o sucesso de seu pagamento"
            />
          </div>

          <p className="text-center">
            <span className="font-bold">pagamento efetuado com sucesso!</span>
            <br />
            <span>
              seus serviços extras ficarão disponíveis assim que recebermos a
              confirmação
            </span>
          </p>

          <Button
            color="neon"
            variant="solid"
            onClick={() => {
              history.replace('/extra-services');
            }}
          >
            entendi
          </Button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ExtraServicesCheckoutSuccess;
