export interface IProgressivebar {
  steps?: string[];
  width: string;
}

const Progressivebar: React.FC<IProgressivebar> = ({ steps, width }) => {
  return (
    <div className="w-full">
      <div className="" aria-hidden="true">
        <div className="bg-gray-100 rounded-full overflow-hidden">
          <div className="h-2 bg-neon-900 rounded-full" style={{ width }} />
        </div>
        {steps && (
          <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600  mt-6">
            {steps.map((step: string, index) => (
              <div
                key={`progressivebar-steps-text-${index}`}
                className="text-neon-900"
              >
                {step}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Progressivebar;
