import { FormValues as CreateCardData } from '../pages/Card';
import { Card } from '../types/card';
import { api } from './config';

export const listCards = async () => {
  const { data } = await api.get<Card[]>(`/v2/partners/gateway/cards`);

  return data;
};

export const findCard = async (id: string) => {
  const { status, data } = await api.get(`/v2/partners/gateway/cards/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createCard = async ({
  holder_name,
  holder_document,
  number,
  exp_year,
  exp_month,
  cvv,
  billing_address,
}: CreateCardData) => {
  let formData = new FormData();
  formData.append('holder_name', holder_name);
  formData.append('holder_document', holder_document);
  formData.append('number', number);
  formData.append('exp_year', exp_year);
  formData.append('exp_month', exp_month);
  formData.append('cvv', cvv);
  formData.append('billing_address.city', billing_address.city);
  formData.append('billing_address.state', billing_address.state);
  formData.append('billing_address.zip_code', billing_address.zip_code);
  formData.append('billing_address.country', billing_address.country);
  formData.append('billing_address.line_1', billing_address.line_1);
  formData.append(
    'billing_address.line_2',
    billing_address.line_2 || billing_address.line_1
  );

  const { status, data } = await api.post(
    '/v2/partners/gateway/cards',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const deleteCard = async (id: string) => {
  const { status, data } = await api.delete(`/v2/partners/gateway/cards/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
