import { memo, useCallback, useEffect, useRef, useState } from 'react';

export type Option = {
  key: string;
  label: string;
  value: any;
};

type MultiSelectProps = {
  label?: string;
  options: Option[];
  values: Option[];
  placeholder?: string;
  onChange: (value: Option[]) => void;
};

export const MultiSelect = memo(
  ({ label, options, values, placeholder, onChange }: MultiSelectProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [filtered, setFiltered] = useState<Option[]>([]);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleSearch = useCallback(() => {
      if (search === '') {
        return options;
      }
      const data = options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
      setFiltered(data);
    }, [search, options]);

    const handleAdded = (item: Option) => {
      try {
        const itemExist = values.find((value) => value.key === item.key);

        if (itemExist) {
          return;
        }

        onChange([...values, item]);
      } catch (error) {
      } finally {
        setSearch('');
        setOpen(false);
      }
    };

    const handleDelete = (item: Option) => {
      try {
        const filtered = values.filter((value) => value.key !== item.key);
        onChange(filtered);
      } catch (error) {
      } finally {
        setSearch('');
        setOpen(false);
      }
    };

    useEffect(() => {
      handleSearch();
    }, [search, options, handleSearch]);

    useEffect(() => {
      function handleClickOutside(event: any) {
        event.stopPropagation();

        if (
          containerRef.current &&
          !containerRef.current.contains(event.target)
        ) {
          setOpen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });

    return (
      <div
        ref={containerRef}
        onFocus={() => setOpen((prevState) => !prevState)}
        className="w-full flex flex-col mx-auto"
      >
        <label className="text-sm">Associados</label>
        <div className="w-full">
          <div className="flex flex-col items-center relative">
            <div className="w-full  svelte-1l8159u">
              <div className="p-0.5 flex border border-gray-200 bg-white rounded svelte-1l8159u">
                <div className="flex flex-auto flex-wrap">
                  {values.map((option) => (
                    <div className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-neon-500 bg-white border border-neon-500 ">
                      <div className="text-xs font-normal leading-none max-w-full flex-initial">
                        {option.label.split(' ')[0]}
                      </div>
                      <div className="flex flex-auto flex-row-reverse">
                        <div onClick={() => handleDelete(option)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-x cursor-pointer hover:text-neon-500 rounded-full w-4 h-4 ml-2"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex-1">
                    <input
                      onChange={(event) => setSearch(event.target.value)}
                      placeholder={placeholder || ''}
                      value={search}
                      className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                    />
                  </div>
                </div>
                <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u">
                  <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-up w-4 h-4"
                    >
                      <polyline points="18 15 12 9 6 15"></polyline>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {open && (
              <div
                className="absolute shadow bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj"
                style={{ top: '100%', maxHeight: 300 }}
              >
                <div className="flex flex-col w-full">
                  {filtered.map((option) => (
                    <div
                      className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-gray-100"
                      onClick={() => handleAdded(option)}
                    >
                      <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-gray-100">
                        <div className="w-full items-center flex">
                          <div className="mx-2 leading-6">{option.label}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
