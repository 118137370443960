import { api } from '../config';
import { CreateOrderProps } from '../types/subscriptions';

export const deposits = async (params = {}) => {
  const { status, data } = await api.get('/v1/partners/deposits', { params });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createDeposit = async (payload: CreateOrderProps) => {
  const { status, data } = await api.post(
    '/v1/payments/pagarme/deposit',
    payload
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
