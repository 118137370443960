import { Listbox } from '@headlessui/react';
import { startOfToday, subDays } from 'date-fns';
import { Button, Checkbox, IconBs, Pagination, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import useAuth from '../../../hooks/useAuth';
import { api } from '../../../services/config';
import { formatShort } from '../../../utils/date';
import errorHandling from '../../../utils/error_handling';
import { formatInCents } from '../../../utils/money';
import ReactivateModal from './ReactivateModal';

import { MultiSelect, Option } from '../../../components/MultiSelect';
import { NotAccess } from '../../../components/NotAccess';
import { customersCashbackRequest } from '../../../services/cashbacksExpired';
import { checkPartnersContractsItemsReportAccessPermission } from '../../../services/contracts';
import { CashbackData } from '../../../types/cashback';
import { PartnersContractsItemsPermission } from '../../../types/partner-contracts-items-permission';
import { EndDate } from '../../CreatePromotion/components/Form/EndDate';
import { StartDate } from '../../CreatePromotion/components/Form/StartDate';

const LIMIT = 10;

const now = startOfToday();

const DATE_INTERVAL = [
  { key: 1, label: 'Últimos 180 dias', value: subDays(now, 180) },
  { key: 2, label: 'Últimos 120 dias', value: subDays(now, 120) },
  { key: 3, label: 'Últimos 90 dias', value: subDays(now, 90) },
  { key: 4, label: 'Últimos 60 dias', value: subDays(now, 60) },
  { key: 5, label: 'Últimos 30 dias', value: subDays(now, 30) },
  { key: 6, label: 'Personalizado', value: 1 },
];

export const Expired = () => {
  const { company, loading } = useAuth();

  const [cashbacksExpired, setCashbacksExpired] = useState<CashbackData[]>([]);
  const [cashbacksExpiredMeta, setCashbacksExpiredMeta] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isLoadingPermissions, setIsLoadingPermissions] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(
    cashbacksExpiredMeta?.current_page || 1
  );
  const [isOpenReactivateModal, setIsOpenReactivateModal] = useState(false);
  const [selectedCashbacks, setSelectedCashbacks] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedCashback, setSelectedCashback] = useState(null);
  const [intervalFilter, setIntervalFilter] = useState<Option | undefined>(
    undefined
  );
  const [associates, setAssociates] = useState<Option[]>([]);
  const [associateFilter, setAssociateFilter] = useState<Option[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [reportPermission, setReportPermission] =
    useState<PartnersContractsItemsPermission>();
  const hasPermissionInExpiredPage = reportPermission?.success;

  const toggleSelection = (cashbackId: string) => {
    if (selectedCashbacks.includes(cashbackId)) {
      setSelectedCashbacks(selectedCashbacks.filter((id) => id !== cashbackId));
    } else {
      setSelectedCashbacks([...selectedCashbacks, cashbackId]);
    }
  };

  const selectAllCashbacks = () => {
    if (selectAll) {
      setSelectedCashbacks([]);
    } else {
      const allCashbackIds = cashbacksExpired.map(
        (cashback: any) => cashback.id
      );
      setSelectedCashbacks(allCashbackIds);
    }
    setSelectAll(!selectAll);
  };

  const total: number = cashbacksExpiredMeta?.total || 0;

  const openReactivateModal = (cashback: any) => {
    setSelectedCashback(cashback);
    setIsOpenReactivateModal(true);
  };

  const closeReactivateModal = () => {
    setSelectedCashback(null);
    setIsOpenReactivateModal(false);
  };

  const getCashbacksExpired = useCallback(async () => {
    if (!company?.id) return;

    setIsLoading(true);

    try {
      const params = {
        limit: LIMIT,
        page: page,
        startDate:
          intervalFilter?.value === 1 ? startDate : intervalFilter?.value,
        endDate:
          intervalFilter?.value === 1
            ? endDate
            : intervalFilter?.value !== undefined
            ? now
            : undefined,
        customers: associateFilter.flatMap((customer) => customer.value),
      };

      const headers = {
        company_id: company.head_office_uuid ?? company.id,
      };

      const response = await api.get('/v2/partners/cashback/expired', {
        params,
        headers,
      });

      const { data, status } = response;

      if (status === 200 && data) {
        setCashbacksExpired(data.data);
        if (data.meta) {
          setCashbacksExpiredMeta(data.meta);
        }
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
        if (status !== 404 && data) {
          errorHandling(null, data.message, 'coral');
        }
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [page, company, associateFilter, intervalFilter, startDate, endDate]);

  const getCustomers = useCallback(async () => {
    try {
      const response = await customersCashbackRequest();

      const options = response.map((customer) => {
        return {
          key: customer.id,
          label: customer.full_name,
          value: customer.id,
        };
      });

      setAssociates(options);
    } catch (error) {}
  }, []);

  const handleGetCheckPermission = useCallback(async () => {
    setIsLoadingPermissions(true);
    try {
      const response = await checkPartnersContractsItemsReportAccessPermission(
        'recovery_cashback'
      );
      setReportPermission(response);
    } catch (error) {
      console.log('ocorreu um erro ao carregar permissões');
    } finally {
      setIsLoadingPermissions(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([
        handleGetCheckPermission(),
        getCashbacksExpired(),
        getCustomers(),
      ]);
    })();
  }, [getCashbacksExpired, getCustomers, handleGetCheckPermission]);

  return (
    <>
      {(loading || isLoadingPermissions) && (
        <div className="h-full flex items-center">
          <Loading />
        </div>
      )}

      {!hasPermissionInExpiredPage && !isLoadingPermissions && (
        <NotAccess>
          <strong className="mt-4">
            Ops! Esta ação não está disponível no seu plano atual.
          </strong>
          <text className="mt-2">
            Que tal fazer um upgrade e liberar essa funcionalidade?
          </text>
        </NotAccess>
      )}

      {hasPermissionInExpiredPage && !isLoadingPermissions && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-1 items-end gap-3 sm:py-6 pb-6">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
              <div className="flex w-full flex-col">
                <MultiSelect
                  options={associates}
                  values={associateFilter}
                  placeholder={
                    associateFilter.length === 0 ? 'Filtrar por associados' : ''
                  }
                  onChange={(options) => setAssociateFilter(options)}
                />
              </div>
              <div className="flex w-full flex-col relative">
                <Listbox
                  value={intervalFilter}
                  onChange={(item) => {
                    if (item.value === 1) {
                      setStartDate(String(subDays(now, 30)));
                      setEndDate(String(now));
                    }
                    setIntervalFilter(item);
                  }}
                >
                  <div className="-mt-1">
                    <Listbox.Label className="text-sm">intervalo</Listbox.Label>
                    <Listbox.Button className=" relative form-input p-2 focus:ring-neon-500 border-gray-300 focus:border-neon-500 block w-full sm:text-sm border rounded-md text-left">
                      {intervalFilter
                        ? intervalFilter.label
                        : 'selecione um intervalo'}
                      <IconBs.BsChevronDown className="absolute top-4 right-2" />
                    </Listbox.Button>
                  </div>

                  <Listbox.Options className="absolute z-10 top-16 max-h-60 left-0 right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neon-900 ring-opacity-5 focus:outline-none sm:text-sm">
                    {DATE_INTERVAL.map((item) => (
                      <Listbox.Option
                        key={item.key}
                        value={item}
                        className="relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-gray-100 hover:text-gray-700"
                      >
                        {item.label}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Listbox>
              </div>
              {intervalFilter?.value === 1 && (
                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="startDate" className="text-sm">
                      data inicial
                    </label>
                    <StartDate
                      onChange={(data: string) =>
                        setStartDate(new Date(data).toISOString())
                      }
                      value={startDate}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="endDate" className="text-sm">
                      data final
                    </label>
                    <EndDate
                      onChange={(data: string) =>
                        setEndDate(new Date(data).toISOString())
                      }
                      value={endDate}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
              <div className="flex w-full flex-col">
                <Button
                  type="button"
                  onClick={selectAllCashbacks}
                  normal
                  variant={selectAll ? 'solid' : 'outline'}
                  color="neon"
                >
                  {selectAll ? 'desmarcar todos' : 'selecionar todos'}
                </Button>
              </div>
              <div className="flex w-full flex-col">
                <Button
                  type="button"
                  onClick={() => openReactivateModal(null)}
                  disabled={selectedCashbacks.length <= 0}
                  normal
                  variant="solid"
                  color="neon"
                >
                  reativar selecionados
                </Button>
              </div>
              <div className="flex w-full flex-col">
                <Button
                  type="button"
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setIntervalFilter(undefined);
                    setAssociateFilter([]);
                  }}
                  normal
                  variant="solid"
                  color="neon"
                >
                  Limpar filtro
                </Button>
              </div>
            </div>
          </div>
          <ReactivateModal
            isOpen={isOpenReactivateModal && !selectedCashback}
            selectedCashbacks={selectedCashbacks}
            closeModal={closeReactivateModal}
            afterSave={() => {
              getCashbacksExpired();
            }}
          />
          {cashbacksExpired.length > 0 ? (
            <>
              <section className="flex items-center flex bg-white sm:rounded-md rounded mb-2 pr-2 mr-24">
                <div className="flex flex-col">
                  <div className="ml-4 mr-2">
                    <Checkbox
                      id="all"
                      label=""
                      name="all"
                      onChange={selectAllCashbacks}
                      checked={selectAll}
                      className="w-auto teste"
                    />
                  </div>
                </div>
                <section className="w-full flex justify-between pr-10 pl-2">
                  <p className="text-left flex h-full mt-1">
                    <span
                      className="text-xs w-full font-bold text-gray uppercase"
                      style={{ color: 'gray' }}
                    >
                      ASSOCIADO
                    </span>
                  </p>
                  <p className="text-center flex h-full mt-1 w-16 items-center">
                    <span
                      className="text-xs w-full font-bold text-gray uppercase"
                      style={{ color: 'gray' }}
                    >
                      DATA
                    </span>
                  </p>
                </section>
                <p className="text-center flex px-5 h-full mt-1 w-32 items-center">
                  <span
                    className="text-xs w-full font-bold text-gray uppercase"
                    style={{ color: 'gray' }}
                  >
                    VALOR
                  </span>
                </p>
              </section>
              {cashbacksExpired.map((cashback) => (
                <section
                  key={`cashbacksExpired_${cashback.id}`}
                  className="flex items-center flex bg-white shadow sm:rounded-md rounded mb-4 border border-gray-100"
                  style={{ height: '49px' }}
                >
                  <ReactivateModal
                    isOpen={
                      isOpenReactivateModal && cashback.id === selectedCashback
                    }
                    selectedCashbacks={[cashback.id]}
                    closeModal={closeReactivateModal}
                    afterSave={() => {
                      getCashbacksExpired();
                    }}
                  />

                  <div className="flex flex-col">
                    <div className="ml-4 mr-2">
                      <Checkbox
                        id={cashback.id}
                        label=""
                        name={cashback.id}
                        onChange={() => toggleSelection(cashback.id)}
                        checked={selectedCashbacks.includes(cashback.id)}
                        className="w-auto teste"
                      />
                    </div>
                  </div>

                  <section className="w-full flex justify-between p-2">
                    <h4 className="flex text-base font-bold">
                      {`${cashback.metadata.customer?.full_name}`}
                    </h4>
                    {cashback.created_at && (
                      <p className="text-center flex h-full mt-1 w-24 items-center">
                        <span
                          className="text-xs w-full font-bold text-gray uppercase"
                          style={{ color: 'gray' }}
                        >
                          {formatShort(
                            cashback.release_date_expiration,
                            false,
                            'formatDateReceivedYearMonthDay'
                          )}
                        </span>
                      </p>
                    )}
                  </section>
                  <p className=" text-center flex px-2 bg-gray-100 h-full w-40 items-center">
                    <span className="w-full font-bold text-neon-900 uppercase">
                      {formatInCents(Number(cashback.value_in_cents))}
                    </span>
                  </p>
                  <Button
                    type="button"
                    onClick={() => openReactivateModal(cashback.id)}
                    normal
                    variant="solid"
                    color="neon"
                    className="rounded-r"
                    style={{
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                    }}
                  >
                    reativar
                  </Button>
                </section>
              ))}
            </>
          ) : (
            <section className="w-full flex justify-center py-4">
              <h3 className="text-center text-lg text-gray-500">
                nenhum cashback expirado encontrado
              </h3>
            </section>
          )}

          {isLoading && <Loading />}

          <section className="flex justify-center mt-4">
            <Pagination
              onPrev={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
              onPage={setPage}
              page={page}
              total={total}
              perPage={cashbacksExpiredMeta?.per_page}
            />
          </section>
        </>
      )}
    </>
  );
};
