import { api } from '../config';
import { CreateWithdrawalsProps } from '../types/withdrawals';

export const withdrawals = async (params = {}) => {
  const { status, data } = await api.get('/v1/partners/withdrawals', {
    params,
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getWithdrawal = async (id: string) => {
  const { status, data } = await api.get(`/v1/partners/withdrawals/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createWithdrawal = async (payload: CreateWithdrawalsProps) => {
  const { status, data } = await api.post('/v1/partners/withdrawals', payload);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
