import { FiltersProps, STATUS } from '../pages/Orders/types';
import { api } from './config';

type DeleteOrdersParams = {
  id: string;
  type: 'order' | 'preorder';
  companyId?: string;
};

export const fetchOrders = async ({
  limit = 10,
  status = STATUS.opened,
  page = 1,
}: FiltersProps) => {
  const { status: statusCode, data } = await api.get('/v1/partners/orders', {
    params: { limit, status, page },
  });

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const fetchOrder = async (id: string) => {
  const { status, data } = await api.get(`/v1/partners/orders/${id}`, {});

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const updateStatus = async (
  id: string,
  status: STATUS,
  code?: string
) => {
  const { status: statusCode, data } = await api.put(
    `/v1/partners/orders/${id}`,
    {
      status,
      code,
    }
  );

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const deliverOrder = async (id: string, code: string) => {
  const { status: statusCode, data } = await api.put(
    `/v1/partners/orders/${id}`,
    {
      status: STATUS.delivered,
      code,
    }
  );

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const checkUser = async (document_number: string) => {
  const { status: statusCode, data } = await api.get(
    `/v1/partners/orders/${document_number}`
  );

  if (statusCode === 201 && data) {
    return data;
  }

  return false;
};

export const checkAssociate = async (document_number: string) => {
  const { status: statusCode, data } = await api.get(
    `/v1/partners/orders/customers/${document_number}`
  );

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const checkInOrder = async (
  cellphone: string,
  document_number: string,
  order_value_in_cents: number,
  cashback_in_percent: number | null,
  used_cashback_in_cents?: number,
  data_preview?: any,
  promotion_id?: string
) => {
  const { status: statusCode, data } = await api.post('/v1/partners/orders', {
    cellphone,
    document_number,
    order_value_in_cents: order_value_in_cents / 100,
    cashback_in_percent,
    used_cashback_in_cents,
    data_preview,
    promotion_id,
  });

  if (statusCode === 201 && data) {
    return data;
  }

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const checkInOrderWithSelectedCompany = async (
  cellphone: string,
  document_number: string,
  order_value_in_cents: number,
  cashback_in_percent: number,
  companySelectedId: string,
  used_cashback_in_cents?: number,
  data_preview?: any,
  promotion_id?: string
) => {
  const { status: statusCode, data } = await api.post(
    '/v1/partners/orders',
    {
      cellphone,
      document_number,
      order_value_in_cents: order_value_in_cents / 100,
      cashback_in_percent,
      used_cashback_in_cents,
      data_preview,
      promotion_id,
    },
    {
      headers: {
        company_id: companySelectedId,
      },
    }
  );

  if (statusCode === 201 && data) {
    return data;
  }

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};

export const deleteOrder = async ({
  id,
  type,
  companyId,
}: DeleteOrdersParams) => {
  let config: any = {};

  if (companyId) {
    config.headers = {
      company_id: companyId,
    };
  }

  const { status, data } = await api.delete(`/v1/partners/orders/${id}`, {
    data: { type },
    headers: config.headers,
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const previewCheckin = async (
  document_number: string,
  order_value_in_cents: number,
  cashback_in_percent: number,
  used_cashback_in_cents: boolean
) => {
  const { status: statusCode, data } = await api.post(
    '/v1/partners/orders/preview',
    {
      document_number,
      order_value_in_cents,
      cashback_in_percent,
      used_cashback_in_cents,
    }
  );

  if (statusCode === 201 && data) {
    return data;
  }

  if (statusCode === 200 && data) {
    return data;
  }

  return false;
};
