import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useOrders as useOrdersHook } from './hooks';
import { Order, OrdersFilters, STATUS } from './types';

type ContextProps = {
  fetchingOrder: boolean;
  fetchingOrders: boolean;
  fetchingInProgressOrders: boolean;
  fetchingForDeveliveryOrders: boolean;
  fetchingDeliveredOrders: boolean;
  showningConfirmationModal: boolean;
  orderId: string | null;
  order: Order;
  openedOrders: Order[];
  inProgressOrders: Order[];
  ordersForDelivery: Order[];
  ordersDelivered: Order[];
  changeStatus: (id: string, status: STATUS) => Promise<void>;
  loadOrders: ({ limit }?: OrdersFilters) => Promise<void>;
  getOrder: (id: string) => Promise<void>;
  confirmCode: (id: string, code: string) => Promise<void>;
  showConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderId: React.Dispatch<React.SetStateAction<string | null>>;
  pageDelivered: number;
  totalDelivered: number;
  setPageDelivered: (_page: number) => void;
  pageForDelivered: number;
  totalForDelivered: number;
  setPageForDelivered: (_page: number) => void;
  pageOpenedOrders: number;
  totalOpenedOrders: number;
  setPageOpenedOrders: (_page: number) => void;
  pageInProgressOrders: number;
  totalInProgressOrders: number;
  setPageInProgressOrders: (_page: number) => void;
};

const Context = createContext<ContextProps>({} as ContextProps);

export const OrdersProvider = ({ children }: { children: ReactNode }) => {
  const [showningConfirmationModal, showConfirmationModal] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);

  const {
    fetchingOrders,
    fetchingInProgressOrders,
    fetchingForDeveliveryOrders,
    fetchingDeliveredOrders,
    openedOrders,
    inProgressOrders,
    ordersForDelivery,
    ordersDelivered,
    order,
    fetchingOrder,
    loadOrders,
    changeStatus,
    confirmCode,
    getOrder,
    pageDelivered,
    totalDelivered,
    setPageDelivered,
    pageForDelivered,
    totalForDelivered,
    setPageForDelivered,
    pageOpenedOrders,
    totalOpenedOrders,
    setPageOpenedOrders,
    pageInProgressOrders,
    totalInProgressOrders,
    setPageInProgressOrders,
  } = useOrdersHook();

  return (
    <Context.Provider
      value={{
        fetchingOrders,
        fetchingInProgressOrders,
        fetchingForDeveliveryOrders,
        fetchingDeliveredOrders,
        openedOrders,
        inProgressOrders,
        ordersForDelivery,
        ordersDelivered,
        showningConfirmationModal,
        orderId,
        order,
        fetchingOrder,
        loadOrders,
        changeStatus,
        showConfirmationModal,
        setOrderId,
        getOrder,
        confirmCode,
        pageDelivered,
        totalDelivered,
        setPageDelivered,
        pageForDelivered,
        totalForDelivered,
        setPageForDelivered,
        pageOpenedOrders,
        totalOpenedOrders,
        setPageOpenedOrders,
        pageInProgressOrders,
        totalInProgressOrders,
        setPageInProgressOrders,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useOrders = () => useContext<ContextProps>(Context);
