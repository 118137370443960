import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatShort(
  date: string,
  showTime: boolean = false,
  variant: 'default' | 'formatDateReceivedYearMonthDay' = 'default'
) {
  const dateTimeString =
    variant === 'formatDateReceivedYearMonthDay' ? `${date}T00:00:00` : date;
  return format(
    new Date(dateTimeString),
    `dd MMM yyyy ${showTime ? 'HH:mm' : ''}`,
    { locale: ptBR }
  );
}
