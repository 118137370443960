import errorHandling from '../../utils/error_handling';
import { api } from '../config';

export const transactions = async (params = {}) => {
  try {
    const { status, data } = await api.get('/v1/partners/transactions', {
      params,
    });

    if (status === 200 && data) {
      return data;
    }

    return false;
  } catch (error) {
    const {
      response: { status, data },
    }: any = error;
    if (status && data) {
      errorHandling(null, data.message, 'coral');
    }
  }
};

export const transaction = async (transactionId: string) => {
  const { status, data } = await api.get(
    `/v1/partners/transaction/${transactionId}`
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
