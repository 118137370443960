import { api } from '../config';

export const wallets = async (company_id?: string) => {
  const { status, data } = await api.get('/v1/partners/wallets', {
    ...(company_id && { headers: { company_id } }),
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};
