import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { usePromotionContext } from '../../../../contexts/PromotionContext';

export default function Radio({ value, onChange, error }: any) {
  const { types } = usePromotionContext();

  return (
    <div className="flex flex-col w-full  py-6">
      <div className="flex mx-auto ">
        <RadioGroup value={value} onChange={onChange}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="grid grid-cols-3 gap-3">
            {types.map((typePromotion: any) => (
              <RadioGroup.Option
                key={typePromotion.value}
                value={typePromotion.value}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-neon-300'
                      : ''
                  }
                  ${
                    checked
                      ? 'bg-neon-900 bg-opacity-5 text-white border-2 border-coral-900 ring-offset-2 ring-offset-neon-900'
                      : 'bg-white'
                  }
                    relative shrink flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium text-gray-900`}
                      >
                        {typePromotion.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={`inline text-xs text-gray-500`}
                      >
                        <span>{typePromotion.description}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                </div>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {error && (
        <span className="text-red-500 text-xs pt-5">
          selecione o objetivo da promoção
        </span>
      )}
    </div>
  );
}
