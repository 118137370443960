import { Storage } from '@capacitor/storage';

export const storeData = (key: string, value: string) => {
  Storage.set({ key, value });
};

export const getData = async (key: string) => {
  return await Storage.get({ key });
};

export const removeData = async (key: string) => {
  return await Storage.remove({ key });
};
