import { usePromotionContext } from '../../../contexts/PromotionContext';
import BannerPromotion from '../../../assets/illustrations/banner-promotion.png';
export const OutputImg = () => {
  const { preview, cropAreaAspect } = usePromotionContext();

  const scale = 100 / preview?.croppedArea?.width;
  const transform = {
    x: `${-preview?.croppedArea?.x * scale}%`,
    y: `${-preview?.croppedArea?.y * scale}%`,
    scale,
    width: 'calc(100% + 0.5px)',
    height: 'auto',
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div
      className="relative w-full h-48 overflow-hidden rounded-2xl"
      style={{ paddingBottom: `${100 / cropAreaAspect}%` }}
    >
      <img
        src={preview?.banner?.url || BannerPromotion}
        alt=""
        style={imageStyle}
        className="absolute w-full h-full left-0 top-0 origin-top-left rounded-2xl"
      />
    </div>
  );
};
