export enum TYPE {
  offer = 'offer',
  combo = 'combo',
  discount = 'discount',
}
export enum STATUS {
  actived = 'actived',
  pending = 'pending',
  paused = 'paused',
  refused = 'refused',
  expired = 'expired',
}

export type TimeType = {
  start: string;
  end: string;
};
export type AvailabilityType = {
  days: '0' | '1' | '2' | '3' | '4' | '5' | '6'[];
  time: TimeType;
};
export type PromotionType = {
  id: string;
  company_id: string;
  name: string;
  description: string;
  role: string;
  banner: string;
  type: TYPE;
  availability: AvailabilityType;
  start_date: Date;
  end_date: Date;
  discount_amount: number;
  is_active: boolean;
  status: STATUS;
  tags: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  favorited_count: string;
  activated_count: string;
  promotion_limit_by_customer: number;
};

export interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: any;
  previous_page_url: any;
}

export interface IPromotions {
  meta: Meta;
  data: PromotionType[];
}
