import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Message {
  content: string;
  icon?: string;
  prompt_id?: string;
  response?: string;
}

interface ChatContextType {
  message: Message | null;
  chat: string;
  addMessage: (message: Message | null) => void;
  addChat: (chat: string) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<Message | null>(null);
  const [chat, setChat] = useState<string>('');

  const addMessage = (message: Message | null) => {
    setChat('');
    setMessage(message);
  };

  const addChat = (chat: string) => {
    setChat(chat);
    setMessage(null);
  };

  return (
    <ChatContext.Provider value={{ message, chat, addMessage, addChat }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
};
