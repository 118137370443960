import { useRef } from 'react';

interface DebounceOptions {
  delay?: number;
}

const defaultOptions: DebounceOptions = {
  delay: 300,
};

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  options?: DebounceOptions
): T {
  const mergedOptions = { ...defaultOptions, ...options };
  const { delay } = mergedOptions;
  const timeoutRef = useRef<number | undefined>();

  return ((...args: Parameters<T>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      callback(...args);
    }, delay);
  }) as T;
}
