import { Dialog, Transition } from '@headlessui/react';
import { Button, Select, Toast } from 'oialbert-ui';
import { Fragment, useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import errorHandling from '../../../utils/error_handling';
import { reactivateCashback } from '../../../services/cashbacksExpired';
import { zodResolver } from '@hookform/resolvers/zod';
import { cashback_validity_types } from '../../../utils/texts';

interface ReactivateModalProps {
  isOpen: boolean;
  closeModal(): void;
  selectedCashbacks: string[];
  afterSave(): void;
}

const validValues = ['3', '7', '10', '15', '30'];

const reactivateSchema = z.object({
  new_days: z
    .string({
      required_error: 'campo obrigatório',
    })
    .nonempty({ message: 'campo obrigatório' })
    .refine((value) => validValues.includes(value), {
      message:
        'Valor inválido. Os valores permitidos são 3, 7, 10, 15 ou 30 dias.',
    }),
});

const ReactivateModal = ({
  isOpen,
  closeModal,
  selectedCashbacks,
  afterSave,
}: ReactivateModalProps) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(reactivateSchema),
    defaultValues: {
      new_days: '30',
    },
  });

  const [loading, setLoading] = useState(false);

  type FormValues = z.infer<typeof reactivateSchema>;

  const handleCloseAndResetForm = useCallback(() => {
    closeModal();
    reset();
  }, [closeModal, reset]);

  const reactivate = useCallback(
    async (data: FormValues) => {
      setLoading(true);

      try {
        if (selectedCashbacks.length > 0) {
          await reactivateCashback(selectedCashbacks, data.new_days);
        }

        Toast.success('Cashback reativado com sucesso!');
      } catch (error: any) {
        errorHandling(error, 'Erro ao reativar cashback');
      } finally {
        closeModal();
        setTimeout(() => {
          setLoading(false);
          reset();
          afterSave();
          handleCloseAndResetForm();
        }, 2000);
      }
    },
    [closeModal, handleCloseAndResetForm, selectedCashbacks, reset]
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Reativar cashback{selectedCashbacks.length > 1 ? 's' : ''}
                </Dialog.Title>
                <form
                  className="w-full flex flex-col"
                  onSubmit={handleSubmit(reactivate)}
                >
                  <Controller
                    name="new_days"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        label="validade"
                        id="new_days"
                        error={errors.new_days?.message}
                        placeholder="selecione"
                        options={Object.keys(cashback_validity_types).map(
                          (p) => ({
                            label: cashback_validity_types[p],
                            value: p,
                          })
                        )}
                      />
                    )}
                  />
                  <section className="grid sm:grid-cols-2 gap-4 mt-8 mb-5">
                    <Button
                      className="sm:w-1/2"
                      onClick={handleCloseAndResetForm}
                      variant="outline"
                      color="neon"
                      type="button"
                      full
                    >
                      Cancelar
                    </Button>
                    <Button
                      className="sm:w-1/2"
                      full
                      variant="solid"
                      color="neon"
                      disabled={loading}
                    >
                      Reativar
                    </Button>
                  </section>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReactivateModal;
