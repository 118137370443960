import { Asset } from '../../schemas/Asset';

export enum STATUS {
  opened = 'opened',
  in_progress = 'in_progress',
  in_route = 'in_route',
  waiting = 'waiting',
  canceled = 'canceled',
  delivered = 'delivered',
}

export type PaymentTypes = 'credit_card' | 'debit_card' | 'pix' | 'cash';
export type DeliveryTypes = 'take_away' | 'delivery';

export interface Response {
  orders: Orders;
}

export interface Orders {
  meta: Meta;
  data: Order[];
}

export interface Meta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: any;
  previous_page_url: any;
}

export interface Order {
  id: string;
  customer_id: string;
  is_checkin: boolean;
  company_id: string;
  address_id: string;
  order_price_in_cents: number;
  payment_type: PaymentTypes;
  status: STATUS;
  delivery_type: DeliveryTypes;
  used_cashback_in_cents: number;
  resume: Resume[];
  observations: string;
  created_at: string;
  updated_at: string;
  courier_id: any;
  is_code_checked: boolean;
  address: Address;
  customer: Customer;
}

export interface Resume {
  product_id: string;
  quantity: number;
  product: Product;
  checkin: {
    title: string;
  };
  characteristics?: Characteristic[];
}

export interface Product {
  id: string;
  company_id: string;
  product_category_id: string;
  photo_id: any;
  title: string;
  name?: string;
  photo: Asset;
  description: string;
  price_in_cents: number;
  price_albert_in_cents: number;
  price_cashback_in_cents: number;
  order_limit: number;
  characteristics?: Characteristic[];
  created_at: string;
  updated_at: string;
}

export interface Characteristic {
  title: string;
  description: string;
  is_required: boolean;
  min: number;
  max: number;
  options: Option[];
}

export interface Option {
  title: string;
  quantity: number;
  min: number;
  max: number;
  additional_price_in_cents: number;
}

export interface Address {
  zip_code: string;
  street: string;
  address_number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  id: string;
}

export interface Customer {
  full_name: string;
  cellphone: string;
  id: string;
}

export type FiltersProps = {
  limit?: number;
  status?: STATUS | STATUS[];
  page: number;
};

export type getOrdersProps = FiltersProps & {
  dataCallback: (data: any) => void;
  isLoading: () => void;
  finishCallback: () => void;
  page: number;
};

export type OrdersFilters = {
  limit?: number;
};
