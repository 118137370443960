import { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { add, format } from 'date-fns';
import MaskedInput from 'react-maskedinput';

registerLocale('pt-BR', ptBr);

interface StartDateProps {
  loading?: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string | null;
}

export function StartDate({ loading, value, onChange, error }: StartDateProps) {
  const dateInputRef = useRef(null);
  const [date, setDate] = useState(
    value ? new Date(value) : add(new Date(), { days: 1 })
  );

  const handleStartDate = useCallback(
    (date: Date | null) => {
      if (date) {
        setDate(date);
        onChange(format(date, 'yyyy-MM-dd HH:mm'));
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (value === null) {
      onChange(format(date, 'yyyy-MM-dd HH:mm'));
    }
  }, [date, onChange, value]);

  return (
    <>
      <DatePicker
        ref={dateInputRef}
        selected={date}
        minDate={add(new Date(), { days: 2 })}
        locale="pt-BR"
        dateFormat={'dd/MM/yyyy HH:mm'}
        customInput={
          <MaskedInput mask="##/##/#### ##:##" placeholder="dd/mm/yyyy HH:mm" />
        }
        timeInputLabel="Horário:"
        showTimeInput
        onChange={handleStartDate}
        className="form-input p-2 focus:ring-neon-500 border-gray-300 focus:border-neon-500 block w-full sm:text-sm border rounded-md"
      />
      {error && (
        <p className={`mt-3 text-xs text-neon-900 w-full lowercase`}>
          * {error}
        </p>
      )}
    </>
  );
}
