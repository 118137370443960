import { LayoutBase } from '../../components/LayoutBase';
import { Expired } from './components/Expired';

const FinancialOrders = () => {
  return (
    <LayoutBase title="Cashbacks expirados">
      <section className="sm:flex lg:p-3 gap-3 min-h-full bg-gray-50 ">
        <section className="p-6 rounded-xl w-full shadow-lg shadow-gray-100 bg-white ">
          <div className="hidden sm:flex flex-col">
            <h1 className="text-xl font-semibold">Cashbacks expirados</h1>
            <h2 className="text-gray-500">listar cashbacks expirados</h2>
          </div>
          <Expired />
        </section>
      </section>
    </LayoutBase>
  );
};

export default FinancialOrders;
