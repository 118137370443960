import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { useEffect } from 'react';
import classNames from 'classnames';

import './description.css';

export const Description = ({
  value,
  onChange,
  placeholder = 'faça uma descrição.',
  error,
  isReadOnly,
}: {
  value: string | undefined;
  placeholder?: string;
  onChange: (_value: string) => void;
  error: string | undefined;
  isReadOnly?: boolean;
}) => {
  const theme = 'snow';
  const formats = ['bold', 'link', 'color'];
  const modules = {
    toolbar: [
      ['bold', 'link'],
      [{ color: ['#646464', 'rgba(255, 0, 90, 1)'] }],
    ],
  };

  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value ?? '');
      quill.blur();
      quill.on('text-change', () => onChange(quill.root.innerHTML));

      quill.root.contentEditable = !isReadOnly ? 'true' : 'false';
    }
  }, [quill, isReadOnly]);

  return (
    <>
      <section
        ref={quillRef}
        className={classNames(
          'description shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-b-md',
          isReadOnly ? 'bg-gray-200' : ''
        )}
        style={{ textTransform: 'none' }}
      />
      {error && (
        <p className={`mt-2 text-sm text-neon-900 w-full lowercase`}>
          * {error}
        </p>
      )}
    </>
  );
};
