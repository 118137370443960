import { useState, useCallback, createContext, ReactNode } from 'react';
import { UseWalletProps } from '../hooks/useWallet';
import errorHandling from '../utils/error_handling';

import { wallets } from '../services/financial/wallets';
import type { Wallet } from '../types/wallets';

import { transactions } from '../services/financial/transactions';
import type { Transaction } from '../types/transactions';

import { deposits } from '../services/financial/deposits';
import { balanceCharges } from '../services/financial/balanceCharges';
import type { Deposit } from '../types/deposits';

import {
  withdrawals,
  createWithdrawal,
} from '../services/financial/withdrawals';
import type { Withdrawals } from '../types/withdrawals';

import { bankAccounts } from '../services/financial/bankAccounts';
import type { BankAccount } from '../types/bankAccounts';

import { banks, createBank } from '../services/financial/banks';
import type { Bank } from '../types/banks';

type WalletProviderProps = {
  children: ReactNode;
};

export const WalletContext = createContext<UseWalletProps>(
  {} as UseWalletProps
);

const WalletProvider = ({ children }: WalletProviderProps) => {
  const [dataWallets, setDataWallets] = useState<Wallet | null>(null);
  const [dataTransactions, setDataTransactions] = useState<Transaction[]>([]);
  const [dataTransactionsOrders, setDataTransactionsOrders] = useState<
    Transaction[]
  >([]);
  const [dataBalanceCharge, setDataBalanceCharge] = useState<Deposit[]>([]);
  const [dataDeposits, setDataDeposits] = useState<Deposit[]>([]);
  const [dataWithdrawals, setDataWithdrawals] = useState<Withdrawals[]>([]);
  const [dataBankAccounts, setDataBankAccounts] = useState<BankAccount[]>([]);
  const [dataBanks, setDataBanks] = useState<Bank[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTransactions, setLoadingTransactions] =
    useState<boolean>(false);
  const [loadingTransactionsOrders, setLoadingTransactionsOrders] =
    useState<boolean>(false);
  const [loadingDeposits, setLoadingDeposits] = useState<boolean>(false);
  const [loadingBalanceCharges, setLoadingBalanceCharges] =
    useState<boolean>(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState<boolean>(false);
  const [loadingBankAccounts, setLoadingBankAccounts] =
    useState<boolean>(false);
  const [loadingBanks, setLoadingBanks] = useState<boolean>(false);
  const [loadingCreateBanks, setLoadingCreateBanks] = useState<boolean>(false);
  const [loadingCreateWithdrawal, setLoadingCreateWithdrawal] =
    useState<boolean>(false);

  const [createdWithdrawal, setCreatedWithdrawal] = useState<boolean>(false);

  const [pageDeposits, setPageDeposits] = useState<number>(1);
  const [pageBalanceCharges, setPageBalanceCharges] = useState<number>(1);
  const [pageTransactions, setPageTransactions] = useState<number>(1);
  const [pageTransactionsOrders, setPageTransactionsOrders] =
    useState<number>(1);
  const [pageWithdraws, setPageWithdraws] = useState<number>(1);

  const [totalBalanceCharge, setTotalBalanceCharge] = useState<number>(0);
  const [totalDeposits, setTotalDeposits] = useState<number>(0);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [totalTransactionsOrders, setTotalTransactionsOrders] =
    useState<number>(0);
  const [totalWithdraws, setTotalWithdraws] = useState<number>(0);

  const getWallets = useCallback(async (companyId?: string) => {
    setLoading(true);
    try {
      const response = await wallets(companyId);
      setDataWallets(response.wallet);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar sua cateira', 'crema');
    } finally {
      setLoading(false);
    }
  }, []);

  const getTransactions = useCallback(async () => {
    setLoadingTransactions(true);
    const {
      transactions: { data, meta },
    } = await transactions({ page: pageTransactions });
    if (data) {
      setDataTransactions(data ? data : []);
    }
    if (meta) {
      setTotalTransactions(meta ? meta.total : 0);
    }
    setLoadingTransactions(false);
  }, [pageTransactions]);

  const getTransactionsOrders = useCallback(async () => {
    setLoadingTransactionsOrders(true);
    const {
      transactions: { data, meta },
    } = await transactions({
      page: pageTransactionsOrders,
      type: 'cashback',
    });
    if (data) {
      setDataTransactionsOrders(data ? data : []);
    }
    if (meta) {
      setTotalTransactionsOrders(meta ? meta.total : 0);
    }
    setLoadingTransactionsOrders(false);
  }, [pageTransactionsOrders]);

  const getDeposits = useCallback(async () => {
    setLoadingDeposits(true);
    try {
      const response = await deposits({ page: pageDeposits });
      setDataDeposits(response.deposits.data);
      setTotalDeposits(response ? response?.deposits?.meta.total : 0);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar seus depósitos', 'crema');
    } finally {
      setLoadingDeposits(false);
    }
  }, [pageDeposits]);

  const getBalanceCharges = useCallback(async () => {
    setLoadingBalanceCharges(true);
    try {
      const response = await balanceCharges({ page: pageBalanceCharges });
      setDataBalanceCharge(response.data);
      setTotalBalanceCharge(response ? response?.meta.total : 0);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar seus depósitos', 'crema');
    } finally {
      setLoadingBalanceCharges(false);
    }
  }, [pageBalanceCharges]);

  const getWithdraws = useCallback(async () => {
    setLoadingWithdrawals(true);
    try {
      const response = await withdrawals({ page: pageWithdraws });
      setDataWithdrawals(response.data);
      setTotalWithdraws(response ? response?.meta.total : 0);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar seus saques', 'crema');
    } finally {
      setLoadingWithdrawals(false);
    }
  }, [pageWithdraws]);

  const onCreateWithdraw = useCallback(async (payload) => {
    setLoadingCreateWithdrawal(true);
    try {
      await createWithdrawal(payload);
      errorHandling(null, 'Saque realizado', 'success');
      setCreatedWithdrawal(true);
    } catch (err) {
      errorHandling(err, 'Erro ao solicitar saque', 'crema');
    } finally {
      setLoadingCreateWithdrawal(false);
    }
  }, []);

  const getBankAccounts = useCallback(async () => {
    setLoadingBankAccounts(true);
    try {
      const response = await bankAccounts();
      setDataBankAccounts(response.data);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar contas', 'crema');
    } finally {
      setLoadingBankAccounts(false);
    }
  }, []);

  const getBanks = useCallback(async () => {
    setLoadingBanks(true);
    try {
      const response = await banks();
      setDataBanks(response);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar bancos', 'crema');
    } finally {
      setLoadingBanks(false);
    }
  }, []);

  const onCreateBank = useCallback(async (payload) => {
    setLoadingCreateBanks(true);
    try {
      await createBank(payload);
      errorHandling(null, 'Conta criada', 'success');
    } catch (err) {
      errorHandling(err, 'Erro ao criar conta', 'crema');
    } finally {
      setLoadingCreateBanks(false);
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{
        getWallets,
        getTransactions,
        getTransactionsOrders,
        getDeposits,
        getBalanceCharges,
        getWithdraws,
        onCreateWithdraw,
        createdWithdrawal,
        getBankAccounts,
        getBanks,
        onCreateBank,
        wallets: dataWallets,
        transactions: dataTransactions,
        transactionsOrders: dataTransactionsOrders,
        balanceCharge: dataBalanceCharge,
        deposits: dataDeposits,
        withdrawals: dataWithdrawals,
        bankAccounts: dataBankAccounts,
        banks: dataBanks,
        loading,
        loadingTransactions,
        loadingTransactionsOrders,
        loadingDeposits,
        loadingBalanceCharges,
        loadingWithdrawals,
        loadingBankAccounts,
        loadingBanks,
        loadingCreateBanks,
        loadingCreateWithdrawal,
        pageDeposits,
        totalDeposits,
        setPageBalanceCharges,
        pageBalanceCharges,
        totalBalanceCharge,
        setPageDeposits,
        pageTransactions,
        totalTransactions,
        setPageTransactions,
        pageWithdraws,
        totalWithdraws,
        setPageWithdraws,
        pageTransactionsOrders,
        totalTransactionsOrders,
        setPageTransactionsOrders,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
